@use 'kaseya-styles/mixins/responsive-web-design';
@use 'sass:map';
@use 'primeicons/primeicons.css';
@use 'table/directives/with-first-column-sticky';
@use 'text-highlight/styles/text-highlight';

:root {
  --breakpoint-sm: #{map.get(responsive-web-design.$breakpoints, sm)};
  --breakpoint-md: #{map.get(responsive-web-design.$breakpoints, md)};
  --breakpoint-lg: #{map.get(responsive-web-design.$breakpoints, lg)};
  --breakpoint-xlg: #{map.get(responsive-web-design.$breakpoints, xlg)};
  --breakpoint-xl: #{map.get(responsive-web-design.$breakpoints, xl)};

  --header-height: 56px;
  --left-panel-width: 240px;
  --left-panel-width--collapsed: 56px;

  --dialog-z-index: 1000;
  --dialog-legacy-z-index: 1055;

  --font-size-xsmall: 10px;
  --font-size-small: 12px;
  --font-size: 14px;
  --font-size-xmedium: 16px;
  --font-size-medium: 18px;
  --font-size-xlarge: 24px;
  --font-size-large: 32px;

  --font-family-light: 'Roboto Light', sans serif;
  --font-family-regular: 'Roboto Regular', sans serif;
  --font-family-medium: 'Roboto Medium', sans serif;
  --font-family-bold: 'Roboto Bold', sans serif;
}

html,
body {
  height: 100%;
  color: var(--font-color-dark-blue);
  font-size: var(--font-size);
  line-height: 22px;
  margin: 0;
  padding: 0;
}

* {
  font-family: var(--font-family-regular);

  [hidden] {
    display: none !important;
  }
}

b,
strong {
  font-weight: unset;
  font-family: var(--font-family-bold);
}

iframe {
  border: none;
}
