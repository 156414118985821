.table {
  &--with-right-border {
    &:after {
      position: absolute;
      height: 100%;
      top: 0;
      right: 0;
      content: '';
      border-right: 1px solid #e5e5e5;
      box-shadow: 0 4px 4px 0 #00000026;
    }
  }

  &--with-left-border {
    &:after {
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;
      content: '';
      border-left: 1px solid #e5e5e5;
      box-shadow: 0 4px 4px 0 #00000026;
    }
  }
}
