:root {
  --white: #ffffff;
  --orange: #d97008;
  --yellow: #e5b000;
  --warn-yellow: #7f5f01;
  --red: #d44431;
  --red-light:  #ffa1a1;
  --dark-red: #a61c0a;
  --danger-red: #811508;
  --green: #29a72f;
  --light-gray: #a5adb7;
  --purple: #7326d3;
  --main-area-background-color: #f5f5f5;
  --skeleton-color: #d8dce0;
  --primary-color: #4f64ee;
  --primary-color-light: #7182f1;
  --font-color-light-pale-gray: #f6f7f8;
  --font-color-light-dull-gray: #e5e5e5;
  --font-color-pale-gray: #cccccc;
  --font-color-light-gray: #999999;
  --font-color-gray: #626d7a;
  --font-color-medium-gray: #656565;
  --font-color-dark-gray: #333333;
  --font-color-blue: #1e2867;
  --font-color-dark-blue: #001d30;
  --font-color-disabled: #a5adb7;
  --error-state-color: #a61c0a;
  --link-color: #166efa;
  --pastel-blue-color: #8eb8fd;
  --backdrop-background-color: rgba(54, 62, 78, 0.7);

  --font-size-xsmall: 10px;
  --font-size-small: 12px;
  --font-size: 14px;
  --font-size-xmedium: 16px;
  --font-size-medium: 18px;
  --font-size-xlarge: 24px;
  --font-size-large: 32px;

  --font-family-light: 'Roboto Light', sans serif;
  --font-family-regular: 'Roboto Regular', sans serif;
  --font-family-medium: 'Roboto Medium', sans serif;
  --font-family-bold: 'Roboto Bold', sans serif;
}

@font-face {
  font-family: 'Roboto Regular';
  src: url('assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Light';
  src: url('assets/fonts/Roboto/Roboto-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Bold';
  src: url('assets/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Medium';
  src: url('assets/fonts/Roboto/Roboto-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
