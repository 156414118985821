@layer icons {
  @font-face {
    font-family: "icons";
    src: url("./icons.ttf") format("truetype");
  }

  i[class^="kds-icon-"], i[class*=" kds-icon-"] {
    height: 24px;
    width: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: var(--font-color-dark-blue);
  }

    i[class^="kds-icon-"]:before, i[class*=" kds-icon-"]:before {
    font-family: icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

      .kds-icon-active:before {
      content: "\f101";
    }

      .kds-icon-amex:before {
      content: "\f102";
    }

      .kds-icon-arrow-left:before {
      content: "\f103";
    }

      .kds-icon-arrow-right:before {
      content: "\f104";
    }

      .kds-icon-article:before {
      content: "\f105";
    }

      .kds-icon-autopay:before {
      content: "\f106";
    }

      .kds-icon-ban:before {
      content: "\f107";
    }

      .kds-icon-bank:before {
      content: "\f108";
    }

      .kds-icon-bars-filter-regular:before {
      content: "\f109";
    }

      .kds-icon-calendar-time:before {
      content: "\f10a";
    }

      .kds-icon-calendar:before {
      content: "\f10b";
    }

      .kds-icon-chevron-down:before {
      content: "\f10c";
    }

      .kds-icon-circle-check-solid:before {
      content: "\f10d";
    }

      .kds-icon-circle-check:before {
      content: "\f10e";
    }

      .kds-icon-circle-close-solid:before {
      content: "\f10f";
    }

      .kds-icon-circle-close:before {
      content: "\f110";
    }

      .kds-icon-circle-exclamation-mark:before {
      content: "\f111";
    }

      .kds-icon-circle-pause-regular:before {
      content: "\f112";
    }

      .kds-icon-clock-filled:before {
      content: "\f113";
    }

      .kds-icon-clock:before {
      content: "\f114";
    }

      .kds-icon-close:before {
      content: "\f115";
    }

      .kds-icon-copy:before {
      content: "\f116";
    }

      .kds-icon-credit-card:before {
      content: "\f117";
    }

      .kds-icon-diners-club:before {
      content: "\f118";
    }

      .kds-icon-discover:before {
      content: "\f119";
    }

      .kds-icon-disk:before {
      content: "\f11a";
    }

      .kds-icon-document:before {
      content: "\f11b";
    }

      .kds-icon-ellipsis:before {
      content: "\f11c";
    }

      .kds-icon-exclamation-mark:before {
      content: "\f11d";
    }

      .kds-icon-favorited:before {
      content: "\f11e";
    }

      .kds-icon-folder:before {
      content: "\f11f";
    }

      .kds-icon-gears:before {
      content: "\f120";
    }

      .kds-icon-go-cardless:before {
      content: "\f121";
    }

      .kds-icon-graduation-cap:before {
      content: "\f122";
    }

      .kds-icon-info-circle:before {
      content: "\f123";
    }

      .kds-icon-jcb:before {
      content: "\f124";
    }

      .kds-icon-landmark:before {
      content: "\f125";
    }

      .kds-icon-lightning:before {
      content: "\f126";
    }

      .kds-icon-list:before {
      content: "\f127";
    }

      .kds-icon-mapping:before {
      content: "\f128";
    }

      .kds-icon-mastercard:before {
      content: "\f129";
    }

      .kds-icon-medal-solid:before {
      content: "\f12a";
    }

      .kds-icon-not-mapped:before {
      content: "\f12b";
    }

      .kds-icon-parent-component:before {
      content: "\f12c";
    }

      .kds-icon-pencil:before {
      content: "\f12d";
    }

      .kds-icon-plug:before {
      content: "\f12e";
    }

      .kds-icon-recent-history:before {
      content: "\f12f";
    }

      .kds-icon-remove-group:before {
      content: "\f130";
    }

      .kds-icon-remove:before {
      content: "\f131";
    }

      .kds-icon-sitemap:before {
      content: "\f132";
    }

      .kds-icon-sync:before {
      content: "\f133";
    }

      .kds-icon-ticket:before {
      content: "\f134";
    }

      .kds-icon-time-sync:before {
      content: "\f135";
    }

      .kds-icon-video:before {
      content: "\f136";
    }

      .kds-icon-visa:before {
      content: "\f137";
    }

      .kds-icon-wifi:before {
      content: "\f138";
    }

      .kds-icon-workflow:before {
      content: "\f139";
    }

  i.kds-icon--white {
    color: var(--white);
  }

  i.kds-icon--yellow {
    color: var(--yellow);
  }

  i.kds-icon--gray {
    color: var(--font-color-gray);
  }

  i.kds-icon--light-gray {
    color: var(--light-gray);
  }

  i.kds-icon--red {
    color: var(--red);
  }

  i.kds-icon--dark-red {
    color: var(--dark-red);
  }

  i.kds-icon--green {
    color: var(--green);
  }

  i.kds-icon--orange {
    color: var(--orange);
  }

  i.kds-icon--primary-color {
    color: var(--primary-color);
  }

  i.kds-icon--warn-yellow {
    color: var(--warn-yellow);
  }

  i.kds-icon--danger-red {
    color: var(--danger-red);
  }

  i.kds-icon--red {
    color: var(--red);
  }

  i.kds-icon--purple {
    color: var(--purple);
  }
}